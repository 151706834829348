import Link from "next/link";
const Breadcrumb = ({ slug }) => {
  return (
    <div id='Breadcrumb' className='lg:px-8'>
      <nav>
        <ol className='hidden sm:flex flex-wrap items-center space-y-1 text-xs sm:text-sm text-gray-500 mb-2'>
          <li>
            <Link href='/' passHref>
              <a className='block  hover:text-gray-700'>
                <span className=''> Home </span>
              </a>
            </Link>
          </li>

          <li>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 h-4'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                clipRule='evenodd'
              />
            </svg>
          </li>

          <li>
            <Link href='/toolbox' passHref>
              <a className='block transition-colors hover:text-gray-700'>
                Toolbox
              </a>
            </Link>
          </li>

          <li>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 h-4'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                clipRule='evenodd'
              />
            </svg>
          </li>

          <li>
            <a
              href='#'
              className='block transition-colors hover:text-gray-700 '
            >
              {slug}
            </a>
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
