import Link from "next/link";
import { useEffect, useState } from "react";
import { Brand } from "../snippets";

const Navbar = ({ showMobileMenu, setShowMobileMenu, showAuth }) => {
  const [currentPage, setCurrentPage] = useState("/");
  const [fix, setFix] = useState(false);
  const [unFix, setUnFix] = useState(false);

  const setFixed = () => {
    if (window.scrollY >= 696) {
      setFix(true);
    } else {
      setFix(false);
    }
  };
  const setUnFixed = () => {
    if (window.scrollY >= 50 && window.scrollY < 696) {
      setUnFix(true);
    } else {
      setUnFix(false);
    }
  };

  useEffect(() => {
    const setup = () => {
      window.addEventListener("scroll", setFixed);
      window.addEventListener("scroll", setUnFixed);
      setCurrentPage(window.location.href.split("/")[3]);
    };
    return setup();
  }, [showAuth]);
  return (
    <header
      className={`ease-in-out shadow-sm  ${
        showMobileMenu ? "fixed z-50 w-full" : ""
      } ${fix ? "Fixed" : ""} duration-300  ${
        unFix ? "unFixed" : ""
      } bg-white lg:bg-black text-black lg:text-white ${
        currentPage === "toolbox" ? "lg:bg-brand-black" : ""
      }`}
    >
      <div className=' px-4 py-2 lg:py-2 mx-auto uppercase text-center'>
        <div className='flex items-center justify-center'>
          <div className='flex mr-auto'>
            <Link href='/' passHref>
              <h1 className='Brand--Logo font-display text-xl font-bold  tracking-widest cursor-pointer'>
                Leading Ladies
              </h1>
            </Link>
          </div>

          <nav className='hidden mr-auto space-x-10 text-sm font-bold font-sans lg:flex tracking-wider'>
            <Link href='https://houseofpraise.ca/' passHref>
              <a
                className='duration-300 underline-offset-auto hover:underline hover:underline-offset-2'
                target='_blank'
              >
                House of Praise
              </a>
            </Link>
            <Link href='https://www.balanceliving.org/' passHref>
              <a
                className='duration-300 underline-offset-auto hover:underline hover:underline-offset-2'
                target='_blank'
              >
                Balance LIving
              </a>
            </Link>
            <Link href='https://topeakinsiku.com' passHref>
              <a
                className='duration-300 underline-offset-auto hover:underline hover:underline-offset-2'
                target='_blank'
              >
                Tope Akinsiku
              </a>
            </Link>
            <Link href='/toolbox' passHref>
              <a
                aria-label='Go to Toolbox'
                className='relative duration-300 underline-offset-auto hover:underline hover:underline-offset-2'
              >
                Toolbox
              </a>
            </Link>
          </nav>

          {showAuth === true ? (
            <div className='hidden lg:flex items-center justify-center space-x-3'>
              <Link href='/login' passHref>
                <a
                  className={`text-sm w-28 h-8 flex items-center justify-center ${
                    fix
                      ? "bg-black text-white"
                      : "bg-brand-pink-medium text-black"
                  } tracking-[1px] font-bold rounded-lg`}
                >
                  Get Access
                </a>
              </Link>
            </div>
          ) : (
            <></>
          )}

          {/* MOBILE MENU*/}
          <div className='lg:hidden'>
            <button
              aria-label='Toggle menu'
              onClick={() => {
                setShowMobileMenu(!showMobileMenu);
              }}
              className='p-2'
            >
              <span className='flex flex-col justify-center h-6'>
                <span
                  className={`${
                    showMobileMenu ? "rotate-45  my-0 -translate-x-px" : "my-1"
                  } w-7 h-[1px]  bg-black`}
                ></span>
                <span
                  className={`${
                    showMobileMenu ? "-rotate-45 my-0 -translate-x-px" : "my-1"
                  } w-7 h-[1px]  bg-black`}
                ></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          showMobileMenu ? "opacity-100" : "-z-30 opacity-0 hidden"
        } w-full duration-300 ease-out lg:hidden mobile-menu z-40 absolute bg-white top-18 overflow-x-hidden h-screen `}
      >
        <div className='Mobile--Menu flex flex-col items-center justify-center mt-28 text-2xl'>
          <Link href='https://houseofpraise.ca/' passHref>
            <a
              target='_blank'
              className='uppercase text-center w-full font-bold tracking-wide my-2'
            >
              House of praise
            </a>
          </Link>
          <Link href='https://www.balanceliving.org/' passHref>
            <a
              target='_blank'
              className='w-full text-center uppercase font-bold tracking-wide my-2'
            >
              Balance Living
            </a>
          </Link>
          <Link href='https://topeakinsiku.com' passHref>
            <a
              target='_blank'
              className='w-full text-center uppercase font-bold tracking-wide my-2'
            >
              Tope Akinsiku
            </a>
          </Link>
          <Link href='/toolbox' passHref>
            <a
              className='w-full text-center uppercase font-bold tracking-wide my-2'
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              Toolbox
            </a>
          </Link>

          {showAuth === true ? (
            <div className='hidden lg:flex items-center justify-center space-x-3'>
              <Link href='/api/auth/signin' passHref>
                <a
                  className={`text-sm w-24 h-8 flex items-center justify-center ${
                    fix
                      ? "bg-black text-white"
                      : "bg-brand-pink-medium text-black"
                  } tracking-[1px] font-bold rounded-lg`}
                >
                  Login
                </a>
              </Link>{" "}
              <Link href='/about' passHref>
                <a
                  className={`text-sm w-24 h-8 flex items-center justify-center ${
                    fix ? "bg-black text-white" : "bg-white text-black"
                  } tracking-[1px] font-bold rounded-lg`}
                >
                  Sign Up
                </a>
              </Link>
            </div>
          ) : (
            <></>
          )}
          <div className='mt-24 h-16 w-36'>
            <Brand color={"pink"} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
