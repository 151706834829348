import Link from "next/link";
import React from "react";
import Brand from "../snippets/Brand";
import { useRef, useEffect } from "react";
import SocialMediaWidget from "../snippets/SocialMediaWidget";
import {
  animateSlideInBottom,
  animateText,
  animateTextFade,
} from "../animations";

const Footer = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const SectionRef = sectionRef.current;
    animateText(SectionRef.querySelectorAll("footer .container > div div"), 1, {
      ease: "slow",
    });
  }, []);
  return (
    <footer className='bg-black py-12 lg:py-4' ref={sectionRef}>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row justify-between items-center space-y-16 lg:space-y-0'>
          <div className='w-full lg:w-1/3 flex justify-center lg:justify-start items-center'>
            <div className=' w-60 h-60 relative duration-500 hover:-rotate-[40deg]'>
              <Brand color='pink' w={240} brand='' />
            </div>
          </div>
          <div
            className='w-full lg:w-1/3 grid grid-cols-2 justify-center items-stretch text-white/80 
          font-bold tracking-wider lg:pt-6 text-sm capitalize'
          >
            <div className='column flex flex-col space-y-1 items-center lg:items-start  w-full mx-auto'>
              <p className=' text-sm font-semibold text-white/50'>More</p>
              <Link href='https://houseofpraise.ca' passHref>
                <a className=' '>House of Praise</a>
              </Link>
              <Link href='https://www.balanceliving.org/' passHref>
                <a className=' '>Balance Living</a>
              </Link>
              <Link href='https://topeakinsiku.com' passHref>
                <a className=' '>Tope Akinsiku</a>
              </Link>
              <Link href='/toolbox' passHref>
                <a className='font-bold tracking-wide '>Toolbox</a>
              </Link>
            </div>
            <div className='column z-50 flex flex-col space-y-1 items-center lg:items-start  w-full mx-auto'>
              <p className=' text-sm font-semibold text-white/50'>Policies</p>
              <Link href='https://houseofpraise.ca/privacy' passHref>
                <a
                  className=' font-bold tracking-wide '
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </a>
              </Link>
              <Link href='https://houseofpraise.ca/cookies' passHref>
                <a
                  className='font-bold tracking-wide '
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms & Conditions
                </a>
              </Link>
              <br />
            </div>
          </div>
          <div className='w-full lg:w-1/3'>
            <SocialMediaWidget />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
