import Link from "next/link";

const Button = ({ href, children }) => {
  return (
    <Link href={href} passHref>
      <div className='Btn--Full'>{children}</div>
    </Link>
  );
};

export default Button;
