import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faYoutube,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaWidget = () => {
  return (
    <div className='Social--Media-Icons '>
      <Link href={"https://www.youtube.com/user/hopraise"} passHref>
        <a
          className='Social--Media-Icon text-[#F50B00] z-50'
          target='_blank'
          rel='noopener noreferrer'
          aria-label='youtube'
        >
          <FontAwesomeIcon icon={faYoutube} className='w-8 h-8 z-50' />
        </a>
      </Link>

      <Link href={"https://www.instagram.com/hopraise"} passHref>
        <a
          className='Social--Media-Icon text-white z-50'
          target='_blank'
          rel='noopener noreferrer'
          aria-label='instagram'
        >
          <FontAwesomeIcon icon={faInstagram} className='w-8 h-8' />
        </a>
      </Link>
      <Link href={"https://twitter.com/hopraise"} passHref>
        <a
          className='Social--Media-Icon text-white'
          target='_blank'
          rel='noopener noreferrer'
          aria-label='twitter'
        >
          <FontAwesomeIcon icon={faTwitter} className='w-8 h-8' />
        </a>
      </Link>
      <Link href={"https://facebook.com/hopraise"} passHref>
        <a
          className='Social--Media-Icon text-white'
          target='_blank'
          rel='noopener noreferrer'
          aria-label='facebook'
        >
          <FontAwesomeIcon icon={faFacebookF} className='w-8 h-8' />
        </a>
      </Link>
    </div>
  );
};

export default SocialMediaWidget;
