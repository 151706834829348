import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const animateTitleFade = (element, duration = 1) => {
  try {
    return gsap.fromTo(
      element,
      {
        opacity: 0,
        y: "-20px",
      },
      {
        y: 0,
        x: 0,
        opacity: 1,
        duration: duration,
        stagger: 0.3,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play reverse reverse reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateText = (element, duration = 1, options = {}) => {
  try {
    gsap.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: duration,
        ease: options.ease ? options.ease : "power5.inOut",
        stagger: 0.1,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "bottom 100%",
          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateSlideInUpLeft = (element, duration = 1) => {
  try {
    gsap.fromTo(
      element,
      {
        x: -200,
        y: 100,
      },
      {
        x: 0,
        y: 0,
        duration: duration,
        scrollTrigger: {
          trigger: element,
          toggleActions: "restart none none reverse",
          start: "top 90%",
          end: "bottom 100%",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateSlideInBottomRight = (element, duration = 1) => {
  gsap.fromTo(
    element,
    {
      x: 200,
      y: 150,
    },
    {
      y: -10,
      x: 0,
      duration: duration,
      stagger: 0.1,
      scrollTrigger: {
        trigger: element,
        toggleActions: "restart none none reverse",
        start: "top 90%",
        end: "bottom 100%",
      },
    }
  );
};
const animateTextFade = (element, duration = 1.3) => {
  return gsap.fromTo(
    element,
    {
      opacity: 0,
      y: 20,
    },
    {
      y: 0,
      opacity: 1,
      duration: duration,

      stagger: 0.3,
      scrollTrigger: {
        trigger: element,
        start: "top 90%",
        end: "bottom 100%",
        toggleActions: "restart none none reverse",
      },
    }
  );
};
const animateSlideInLeft = (element, duration = 1.3) => {
  return gsap.fromTo(
    element,
    {
      x: "-100%",
    },
    {
      x: 0,
      duration: duration,
      ease: "power5.inOut",
      stagger: 0.1,
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "bottom 100%",
        toggleActions: "restart none none reverse",
      },
    }
  );
};
const animateSlideInRight = (element, duration = 1.3) => {
  try {
    return gsap.fromTo(
      element,
      {
        x: "100%",
      },
      {
        x: 0,
        duration: duration,
        ease: "power5.inOut",
        stagger: 0.1,
        scrollTrigger: {
          trigger: element,
          start: "top 80%",
          end: "bottom 100%",
          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateSlideInTop = (element, duration = 1.3) => {
  try {
    return gsap.fromTo(
      element,
      {
        y: "-24px",
      },
      {
        y: 0,
        duration: duration,
        ease: "power5.inOut",
        stagger: 0.1,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "bottom 100%",
          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateSlideInBottom = (element, duration = 1.3, options = {}) => {
  try {
    return gsap.fromTo(
      element,
      {
        y: "20%",
      },
      {
        y: 0,
        duration: duration,
        ease: options.ease ? options.ease : "power5.inOut",
        stagger: 0.2,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "bottom 100%",
          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateCascadeLeft = (element, duration = 1.3) => {
  try {
    return gsap.fromTo(
      element,
      {
        x: "50%",
      },
      {
        x: 0,
        y: 0,
        duration: duration,
        stagger: 0.1,

        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "center 100%",

          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateCascadeRight = (element, duration = 1.3) => {
  try {
    return gsap.fromTo(
      element,
      {
        x: -100,
      },
      {
        x: 0,
        y: 0,
        stagger: 0.3,
        ease: "elastic.out",
        duration: duration,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "center 100%",
          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};
const animateDescend = (element, duration = 1.3) => {
  try {
    return gsap.fromTo(
      element,
      {
        y: -100,
      },
      {
        x: 0,
        y: 0,
        duration: duration,
        stagger: 0.1,
        scrollTrigger: {
          trigger: element,
          start: "0% 95%",
          end: "bottom 100%",
          toggleActions: "restart none none reverse",
        },
      }
    );
  } catch (e) {
    console.log("GSAP WARNING", e.message);
  }
};

export {
  animateText,
  animateTitleFade,
  animateTextFade,
  animateSlideInLeft,
  animateSlideInRight,
  animateSlideInTop,
  animateSlideInBottom,
  animateSlideInUpLeft,
  animateSlideInBottomRight,
  animateCascadeLeft,
  animateCascadeRight,
  animateDescend,
};
