const Jumbotron = ({ title, subtitle, direction }) => {
  return (
    <section>
      <div
        id='Jumbotron'
        className={`px-2 lg:px-10 py-4 lg:py-6 flex flex-col  justify-center text-brand-black uppercase`}
      >
        <h2
          className={`Jumbotron--Title' text-3xl lg:text-6xl ${
            direction === "left"
              ? "text-left"
              : direction === "right"
              ? "text-right"
              : "text-center"
          }  font-semibold font-display tracking-[3px] leading-relaxed mb-3`}
        >
          {title}
        </h2>
        <h3
          id='Jumbotron-Subtitle'
          className={`text-xs  ${
            direction === "mr-auto"
              ? "text-left"
              : direction === "right"
              ? "text-right"
              : "mx-auto text-center"
          }  lg:max-w-xl xl:max-w-2xl  mb-0`}
        >
          {subtitle}
        </h3>
      </div>
    </section>
  );
};

export default Jumbotron;
