import Image from "next/image";
import PTAWhite from "../../assets/images/logo-white.png";
import PTABlack from "../../assets/images/logo-black.png";
import BrandLogoPink from "../../assets/images/LL_pinklogo_text.svg";
import BrandLogoBlack from "../../assets/images/LL_blacklogo.svg";
import { useRef } from "react";
import { useEffect } from "react";
import { animateText, animateTextFade } from "../animations";

const Brand = ({ color, w, h, brand }) => {
  const PTAlogo = color === "white" ? PTAWhite : PTABlack;
  const logo = color === "pink" ? BrandLogoPink : BrandLogoBlack;
  const sectionRef = useRef(null);

  useEffect(() => {
    const SectionRef = sectionRef.current;
    animateText(SectionRef.querySelectorAll(".brand"), 2, {
      ease: "ease",
    });
  }, []);

  return (
    <div className='' ref={sectionRef}>
      {brand === "PTA" ? (
        <Image
          className='brand '
          src={PTAlogo}
          alt='logo'
          layout='responsive'
          width={220}
          height={150}
          objectFit='contain'
          sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
        />
      ) : (
        <Image
          className='brand '
          src={logo}
          alt='logo'
          layout='responsive'
          width={w}
          height={w}
          objectFit='contain'
          sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
        />
      )}
    </div>
  );
};

export default Brand;
