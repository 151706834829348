import Brand from "./snippets/Brand";
const LoadingScreen = () => {
  return (
    <div
      id='Loading--Screen'
      className='w-full h-full flex flex-col justify-center items-center '
    >
      <div className='Fade--In h-72 w-72 sm:w-96 sm:h-96 relative '>
        <Brand color='pink' w='300' h='300' brand='' />
      </div>
      <h2
        className={`Jumbotron--Title text-3xl lg:text-6xl text-center font-semibold font-freight text-brand-pink-dark tracking-[5px] leading-relaxed mb-3 uppercase my-16`}
      >
        leading&nbsp;&nbsp;ladies
      </h2>
    </div>
  );
};

export default LoadingScreen;
