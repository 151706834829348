import { Navbar, Footer } from "./";
import { useEffect, useState, useRef } from "react";
import LoadingScreen from "../LoadingScreen";
import Modals from "./Modals";
import { animateTextFade, animateTitleFade } from "../animations";

const Layout = ({ children, showAuth, showLoading, showModal }) => {
  const layoutRef = useRef(null);
  const [loading, setLoading] = useState(showLoading);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2200);
    const LayoutRef = layoutRef.current;

    // animateTitleFade(LayoutRef, LayoutRef.querySelectorAll(".Title-Fade"), 0.8);
    // animateTextFade(LayoutRef, LayoutRef.querySelectorAll(".Text-Fade"), 1.2);
  }, []);

  return (
    <div
      className={`min-h-screen ${
        loading ? "fixed w-screen h-screen overflow-y-hidden" : "relative"
      }  `}
    >
      <>
        {loading ? (
          <></>
        ) : (
          <Navbar
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
            loading={loading}
            showAuth={showAuth}
          />
        )}

        <div className={`absolute ${loading ? "top-0" : "top-16"} lg:top-12`}>
          <div className='relative'>
            {/* <Modals loading={loading} visible={showModal} /> */}
            <div
              className={`${
                loading ? "opacity-100" : " opacity-0 hidden"
              } z-20 absolute duration-400 ease-in-out w-screen h-screen top-0 left-0 bg-white`}
            >
              <LoadingScreen />
            </div>
            <div
              className={`${
                loading ? "opacity-20" : "opacity-100"
              } absolute duration-500 ease-in-out w-screen top-0 left-0`}
            >
              <main
                className={`${
                  showMobileMenu ? "-z-10 " : ""
                } overflow-x-hidden`}
                ref={layoutRef}
              >
                {children}
                <Footer />
              </main>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Layout;
